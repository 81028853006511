import React from 'react';

import type { CarouselSpot } from '~/domains/cms/PromoCarousel/enums';
import usePromoCarousel from '~/domains/cms/PromoCarousel/usePromoCarousel';

import { Carousel } from './Carousel';

type ConnectedCarouselProps = {
  carouselSpot: CarouselSpot;
};

export function ConnectedCarousel({ carouselSpot }: ConnectedCarouselProps) {
  const { data, isLoading, isError } = usePromoCarousel(carouselSpot);

  if (isLoading || isError) {
    return null;
  }

  return <Carousel slides={data} />;
}
