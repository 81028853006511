import React, { useState } from 'react';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useQueryClient } from '@tanstack/react-query';
import type { ContestType } from '@betterpool/api-types/contests-service';

import dt from '~/testing';
import { logError } from '~/common/utils/log';
import { LinkNew } from '~/domains/common/components/Link/Link';
import useIsUserAuthorised from '~/domains/auth/hooks/useIsUserAuthorised';
import XPointSingleton from '~/utils/xpoint';
import IconLocationOff from '~/domains/design-system/icons/IconLocationOff';
import { Title } from '~/domains/design-system/Title';
import { Text } from '~/domains/design-system/Text';
import { Button } from '~/domains/design-system/Button';

import useStyles from './useStyles';

type LocationInfoProps = {
  contestTypes: ContestType[];
  isInLobby?: boolean;
  locationState?: string;
  refreshQueryKey?: string[];
};

function LocationInfo({
  contestTypes,
  isInLobby,
  locationState,
  refreshQueryKey,
}: LocationInfoProps) {
  const [isLocationLoading, setLocationLoading] = useState(false);
  const { classes, theme } = useStyles({
    isError: !!locationState,
  });
  const { t } = useTranslation('contest');
  const queryClient = useQueryClient();
  const isUserAuthorised = useIsUserAuthorised();

  const handleLocationRecheck = async () => {
    setLocationLoading(true);
    try {
      const { errors, token } = await XPointSingleton.getValidLocationToken(0, true);
      if (refreshQueryKey && !errors && token) {
        queryClient.refetchQueries(refreshQueryKey);
      }
    } catch (e) {
      logError('Error refreshing location token', e);
    } finally {
      setLocationLoading(false);
    }
  };

  const invalidContestTypes = contestTypes
    .map((contestType) => t(`detail.locationInfo.contestTypes.${contestType}`))
    .join(' & ');

  return (
    <div className={classes.container}>
      <div data-test-id={dt.contestDetail.page.locationInfo.container} className={classes.heading}>
        <IconLocationOff
          color={locationState ? theme.colors.red[7] : theme.colors.orange[7]}
          size={24}
        />

        <Title tag="h4" variant="subhead-medium" className={classes.title}>
          {locationState
            ? t('detail.locationInfo.titleKnown', { locationState })
            : t('detail.locationInfo.titleUknown')}
        </Title>
      </div>

      <div className={classes.content}>
        <Text variant="body-medium">
          {isInLobby ? (
            <Trans
              i18nKey="contest:detail.locationInfo.copyLobby"
              values={{ invalidContestTypes }}
              components={[
                <LinkNew
                  key="contest-availability"
                  href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                />,
              ]}
            />
          ) : (
            <>
              <Trans
                i18nKey="contest:detail.locationInfo.copyContestDetail"
                values={{ invalidContestTypes }}
                components={[
                  <LinkNew
                    key="contest-availability"
                    href="https://legal.splashsports.com/map/splash-fantasy-contest-availability"
                  />,
                ]}
              />
              <br />
              <br />
              <strong>{t('detail.locationInfo.copyContestDetailAppend')}</strong>
            </>
          )}
        </Text>
        {isUserAuthorised && (
          <Button
            data-test-id={dt.contestDetail.page.locationInfo.recheckLocationButton}
            className={classes.button}
            variant="secondary-stroke"
            size="medium"
            onClick={handleLocationRecheck}
            loading={isLocationLoading}
          >
            {t('detail.locationInfo.checkLocationButtonLabel')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default LocationInfo;
