import { Drawer, Modal, useMantineTheme } from '@mantine/core';
import { useEffect, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { useIsMobile } from '~/domains/common/hooks/mediaQueries';
import type { FullContest } from '~/domains/contest/domains/services/interfaces/full-contest.interface';
import { getContestThumbnail } from '~/domains/contest/domains/common/utils/getContestThumbnail';

import { SignUpModalContent } from './SignUpModalContent';

type SignUpModalProps = {
  contestId: string;
  isOpen: boolean;
  onClose: VoidFunction;
};

export function SignUpModal({ contestId, onClose, isOpen }: SignUpModalProps) {
  const theme = useMantineTheme();
  const isMobile = useIsMobile();
  const router = useRouter();
  const queryClient = useQueryClient();

  const { avatar, name, segments } = useMemo(() => {
    const data = queryClient.getQueryData<FullContest>(['contests', contestId, null]);

    const contestThumbnail = getContestThumbnail({
      contestThumbnailUrl: data?.thumbnail_url,
      createdByAdmin: data?.created_by_admin,
      commissionerId: data?.commissioner?.id,
    });

    return {
      avatar: contestThumbnail,
      name: data?.name,
      segments: data?.settings?.segments,
    };
  }, [contestId, queryClient]);

  useEffect(() => {
    if (onClose) {
      router.events.on('routeChangeStart', onClose);
      router.events.on('routeChangeComplete', onClose);
      router.events.on('routeChangeError', onClose);
      return () => {
        router.events.off('routeChangeStart', onClose);
        router.events.off('routeChangeComplete', onClose);
        router.events.off('routeChangeError', onClose);
      };
    }
  }, [router, onClose]);

  if (isMobile) {
    return (
      <Drawer
        lockScroll
        onClose={onClose}
        opened={isOpen}
        position="bottom"
        styles={{
          header: {
            borderTopLeftRadius: theme.spacing.xl,
            borderTopRightRadius: theme.spacing.xl,
            background: theme.white,
            padding: theme.spacing.md,
            paddingBottom: 0,
            margin: 0,
          },
          drawer: {
            background: 'transparent',
            height: 'auto',
          },
          body: {
            padding: theme.spacing.md,
            background: theme.white,
          },
        }}
      >
        <SignUpModalContent segments={segments} avatar={avatar} name={name} />
      </Drawer>
    );
  }

  return (
    <Modal
      lockScroll
      onClose={onClose}
      opened={isOpen}
      radius="lg"
      size="lg"
      styles={{
        header: {
          marginTop: -9,
        },
      }}
    >
      <SignUpModalContent segments={segments} avatar={avatar} name={name} />
    </Modal>
  );
}
