import { Carousel as MantineCarousel } from '@mantine/carousel';
import { useMediaQuery } from 'react-responsive';
import { useContext, useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';

import { FEATURE } from '~/common/enums/feature.enum';
import { UserContext } from '~/components/providers/UserProvider';
import type { CarouselSlide } from '~/domains/cms/PromoCarousel/types';
import { CarouselSlideTrafficType } from '~/domains/cms/PromoCarousel/enums';
import { useIsFeatureEnabled } from '~/hooks/useIsFeatureEnabled';

import CarouselSlideSwitch from './CarouselSlideSwitch';
import useStyles from './Carousel.styles';

const trafficTypeMatchers = {
  [CarouselSlideTrafficType.ANONYMOUS]: (user) => !user,
  [CarouselSlideTrafficType.USER]: (user) => !!user,
};

const AUTOPLAY_DELAY = 7000;

type CarouselProps = {
  slides: CarouselSlide[];
};

function checkDisplayDates(displayStartDate: string, displayEndDate: string) {
  const currentDateLocal = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });
  const currentDate = new Date(currentDateLocal);

  if (displayStartDate && displayEndDate) {
    const start = new Date(displayStartDate);
    const end = new Date(displayEndDate);
    return currentDate >= start && currentDate <= end;
  }
  if (displayStartDate && !displayEndDate) {
    const start = new Date(displayStartDate);
    return currentDate >= start;
  }
  if (!displayStartDate && displayEndDate) {
    const end = new Date(displayEndDate);
    return currentDate <= end;
  }
  if (!displayStartDate && !displayEndDate) {
    return true;
  }
  return false;
}

export function Carousel({ slides }: CarouselProps) {
  const { classes } = useStyles();
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const { user } = useContext(UserContext);

  const isCountdownPillEnabled = useIsFeatureEnabled(FEATURE.ENABLE_CONTEST_COUNTDOWN_TIMER);

  const autoplay = useRef(Autoplay({ delay: AUTOPLAY_DELAY }));

  const filteredSlides = slides.filter((slide) => {
    if (!slide.published) {
      return false;
    }
    if (!checkDisplayDates(slide.displayStartDate, slide.displayEndDate)) {
      return false;
    }

    const trafficTypeMatches =
      slide.trafficTypes?.map((trafficType) => trafficTypeMatchers[trafficType](user)) ?? [];

    return trafficTypeMatches.includes(true);
  });

  const slidesCount = filteredSlides.length;

  if (slidesCount === 0) {
    return null;
  }

  return (
    <MantineCarousel
      slideSize="100%"
      withIndicators={slidesCount > 1}
      withControls={slidesCount > 1 && !isTablet}
      loop
      slideGap="xl"
      align="start"
      height="auto"
      plugins={filteredSlides.length > 1 ? [autoplay.current] : []}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
      classNames={{
        indicator: classes.indicator,
        indicators: classes.indicators,
        slide: classes.slide,
      }}
    >
      {filteredSlides.map((slide, index) => (
        <MantineCarousel.Slide key={slide.id}>
          <CarouselSlideSwitch
            slide={slide}
            slideIndex={index}
            isCountdownPillEnabled={isCountdownPillEnabled}
          />
        </MantineCarousel.Slide>
      ))}
    </MantineCarousel>
  );
}
