/* eslint-disable no-underscore-dangle */
import React from 'react';

import type { CarouselSlide } from '~/domains/cms/PromoCarousel/types';
import { CarouselSlideType } from '~/domains/cms/PromoCarousel/enums';

import GenericSlide from './Slide/GenericSlide/GenericSlide';
import ContestSlide from './Slide/ContestSlide/ConnectedContestSlide';

type CarouselSlideSwitchProps = {
  slide: CarouselSlide;
  slideIndex: number;
  isCountdownPillEnabled?: boolean;
};

function CarouselSlideSwitch({
  slide,
  slideIndex,
  isCountdownPillEnabled,
}: CarouselSlideSwitchProps) {
  switch (slide.__component) {
    case CarouselSlideType.GENERIC: {
      return <GenericSlide {...slide} />;
    }
    case CarouselSlideType.CONTEST: {
      return (
        <ContestSlide
          {...slide}
          slideIndex={slideIndex}
          isCountdownPillEnabled={isCountdownPillEnabled}
        />
      );
    }
    default: {
      return null;
    }
  }
}

export default CarouselSlideSwitch;
