// Swagger: https://api-dev.splashsports.com/games-service/api#/contest-templates/ContestTemplatesApiController_getSlates

import Cookies from 'js-cookie';

import type { SlatesAndGroupsResponse } from '~/domains/commissioner/interfaces/slates-and-groups.interface';
import { createQueryString } from '~/domains/common/utils/queryString';
import type { ScheduleOptions } from '~/services/contests/interfaces/contest-templates.interface';

import type { League } from '../../domains/commissioner/interfaces/league.interface';

import { gamesServiceInstance, gamesServiceNoAuthInstance } from './games-service.service.instance';
import type { Slate } from './interfaces/slates.interface';

export const getLeagues = async (authToken?: string): Promise<League[]> => {
  const cookieExists = Cookies.get('accessToken');
  const gameInstance = cookieExists ? gamesServiceInstance : gamesServiceNoAuthInstance;
  const response = await gameInstance.get<League[]>('/leagues', {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : undefined,
    },
  });
  return response.data;
};

export const getSlatesAndGroupsByLeagueId = async (
  leagueId: string,
  scheduleOptions?: ScheduleOptions,
  contestTemplateId?: string,
  authToken?: string
): Promise<SlatesAndGroupsResponse> => {
  const qs = createQueryString({
    scheduleType: scheduleOptions?.scheduleType ?? null,
    limit: scheduleOptions?.maxSlateGroupsToRetrieve ?? null,
    partialSlates: scheduleOptions?.partialSlatesAllowed ?? false,
    contestTemplateId: contestTemplateId ?? null,
  });

  const response = await gamesServiceNoAuthInstance.get<SlatesAndGroupsResponse>(
    `/leagues/${leagueId}/slate-groups${qs}`,
    {
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
      },
    }
  );

  return response.data;
};

export const getSlatesBySlateGroupsAndTags = async (
  slateGroupIds: string[],
  optionTagIds?: string[],
  labelIds?: string[],
  authToken?: string
): Promise<
  Promise<{
    slates: Slate[];
    teamsCount: number;
  }>
> => {
  // TODO: Update this to gamesServiceNoAuthInstance once API is updated
  const response = await gamesServiceInstance.post<{
    slates: Slate[];
    teamsCount: number;
  }>(
    `/slate-groups/slates`,
    {
      slateGroupIds,
      optionTagIds: optionTagIds?.length ? optionTagIds : undefined,
      labelIds: labelIds?.length ? labelIds : undefined,
    },
    {
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : undefined,
      },
    }
  );
  return response.data;
};

export const getGamesBySlateGroupAndOptionTags = async (
  leagueId: string,
  slateGroupId?: string,
  optionTagIds?: string[]
) => {
  const response = await gamesServiceInstance.post(
    `/leagues/${leagueId}/slate-groups/${slateGroupId}/games`,
    {
      optionTagIds,
    }
  );

  return { [slateGroupId]: response.data };
};
