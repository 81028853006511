import styled from '@emotion/styled';
import { createStyles } from '@mantine/core';

type UseStylesProps = {
  isError: boolean;
};

const useStyles = createStyles((theme, { isError }: UseStylesProps) => ({
  container: {
    border: '1px solid',
    borderColor: isError ? theme.colors.red[2] : theme.colors.orange[2],
    borderRadius: theme.radius.md,
    marginBottom: theme.spacing.xl,
    background: isError ? theme.colors.red[2] : theme.colors.orange[2],
    overflow: 'hidden',
  },
  heading: {
    display: 'flex',
    gap: theme.other.spacing._8,
    padding: `${theme.other.spacing._8}px ${theme.other.spacing._16}px`,
    color: isError ? theme.colors.red[7] : theme.colors.orange[7],
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.other.spacing._16}px ${theme.other.spacing._16}px`,
    gap: theme.other.spacing._16,
    background: 'white',

    [theme.fn.smallerThan('md')]: {
      flexDirection: 'column',
      gap: theme.other.spacing._16,
    },
  },
  icon: {
    stroke: 'white',
  },
  title: {
    margin: 0,
  },
  button: {
    flexShrink: 0,
    [theme.fn.smallerThan('md')]: {
      width: '100%',
    },
  },
}));

const LocationInfoContainer = styled.div(
  ({ theme }) => `
    position: relative;
    padding: 0 ${theme.spacing.lg}px;

    ${theme.fn.smallerThan('md')} {
      padding: ${theme.spacing.sm}px;
    }
`
);

export default useStyles;
export { LocationInfoContainer };
